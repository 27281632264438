import { useLocation } from "@reach/router";

export function MetaTitle() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Brush Manufacturer Sri Lanka, Brush Exporter, BPPL Holdings PLC";
  } else if (pathname === "404") {
    return "Page not found - BPPL";
  } else if (pathname === "about-us") {
    return "BPPL Holdings | Brush Manufacturer | Brush Exporter | Mop Maker | Broom Maker Worldwide | Brush Back | International Cleaning Tools Producer";
  } else if (pathname === "esg") {
    return "BPPL Holdings | Sustainability | Beira Holdings PLC, Sri Lanka";
  } else if (pathname === "investor-relations") {
    return "BPPL Holdings | Investor Relations | Beira Holdings PLC, Sri Lanka";
  } else if (pathname === "contacts") {
    return "BPPL Holdings | Contact Us | Beira Holdings PLC, Sri Lanka";
  } else if (pathname === "faq-page") {
    return "FAQ | BPPL Holdings | Beira Holdings PLC, Sri Lanka";
  }
}

export function MetaDescription() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Beira Group is the largest brush manufacturer, brush exporter and exporter of sanitary maintenance tools in Asia. We produce brushes, brooms, banisters, dusters and scrubs.";
  } else if (pathname === "404") {
    return "Page not found - BPPL";
  } else if (pathname === "about-us") {
    return "Beira manufactures and Exports a range of high quality, durable cleaning products Especially Brushes , Brooms and Mops for both professional and household applications";
  } else if (pathname === "esg") {
    return "BPPL Holdings PLC is founded on the philosophy of a conscious approach towards sustainability.";
  } else if (pathname === "investor-relations") {
    return "Take a look at the annual reports, interim reports, corporate governance, corporate calendar and broker research of BPPL Holdings";
  } else if (pathname === "contacts") {
    return "Get in touch with BPPL holdings plc for further information. Call us or drop an email and we will get back to you shortly";
  } else if (pathname === "faq-page") {
    return "BPPL Holdings | FAQ | Beira Holdings PLC, Sri Lanka";
  }
}

export function MetaCanonical() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "https://www.bpplholdings.com/";
  } else if (pathname === "404") {
    return "Page not found - BPPL";
  } else if (pathname === "about-us") {
    return "https://www.bpplholdings.com/about-us/";
  } else if (pathname === "esg") {
    return "https://www.bpplholdings.com/esg/";
  } else if (pathname === "investor-relations") {
    return "https://www.bpplholdings.com/investor-relations/";
  } else if (pathname === "contacts") {
    return "https://www.bpplholdings.com/contacts/";
  } else if (pathname === "faq-page") {
    return "https://www.bpplholdings.com/faq-page/";
  }
}

export function MetaType() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "website";
  } else {
    return "article";
  }
}

export function MetaKeywords() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "BPPL";
  } else {
    return "BPPL";
  }
}

export function MetaImage() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "https://storage.cloud.google.com/cdn.bpplholdings.com/REACT/HOME/Bppl-logo-with-box.jpg";
  } else {
    return "https://storage.cloud.google.com/cdn.bpplholdings.com/REACT/HOME/Bppl-logo-with-box.jpg";
  }
}

export function MetaOGTitle() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Brush Manufacturer Sri Lanka | Brush Exporter | BPPL Holdings PLC";
  } else if (pathname === "404") {
    return "Page not found - BPPL";
  } else if (pathname === "about-us") {
    return "BPPL Holdings | Brush Manufacturer | Brush Exporter | Mop Maker | Broom Maker Worldwide | Brush Back | International Cleaning Tools Producer";
  } else if (pathname === "esg") {
    return "BPPL Holdings | Sustainability | Beira Holdings PLC, Sri Lanka";
  } else if (pathname === "investor-relations") {
    return "BPPL Holdings | Investor Relations | Beira Holdings PLC, Sri Lanka";
  } else if (pathname === "contacts") {
    return "BPPL Holdings | Contact Us | Beira Holdings PLC, Sri Lanka";
  } else if (pathname === "faq-page") {
    return "BPPL Holdings | FAQ | Beira Holdings PLC, Sri Lanka";
  }
}

export function MetaOGDescription() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "Beira Group is the largest brush manufacturer, brush exporter and exporter of sanitary maintenance tools in Asia. We produce brushes, brooms, banisters, dusters and scrubs";
  } else if (pathname === "404") {
    return "Page not found - BPPL";
  } else if (pathname === "about-us") {
    return "Beira manufactures and Exports a range of high quality, durable cleaning products Especially Brushes , Brooms and Mops for both professional and household applications";
  } else if (pathname === "esg") {
    return "BPPL Holdings PLC is founded on the philosophy of a conscious approach towards sustainability.";
  } else if (pathname === "investor-relations") {
    return "Take a look at the annual reports, interim reports, corporate governance, corporate calendar and broker research of BPPL Holdings";
  } else if (pathname === "contacts") {
    return "Get in touch with BPPL holdings plc for further information. Call us or drop an email and we will get back to you shortly";
  } else if (pathname === "faq-page") {
    return "BPPL Holdings | FAQ | Beira Holdings PLC, Sri Lanka";
  }
}

export function MetaOGSiteName() {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  if (pathname === "") {
    return "BPPL";
  } else {
    return "BPPL";
  }
}

import React, { useEffect, useState } from "react";

const CookieNotice = () => {
  const [showNotice, setShowNotice] = useState(false);

  useEffect(() => {
    const hasConsented = localStorage.getItem("BPPL Holdings Cookie Consent");
    const delay = setTimeout(() => {
      if (!hasConsented) {
        setShowNotice(true);
      }
    }, 1000);

    return () => clearTimeout(delay);
  }, []);

  const handleAccept = () => {
    localStorage.setItem("BPPL Holdings Cookie Consent", "true");
    setShowNotice(false);
  };

  return (
    showNotice && (
      <div className="cookie-notice-box">
        <div className="cookie-message">
          This website uses cookies and 3rd-party services to offer you a
          better, more personalized, browsing experience with advanced
          accessibility enhancements. By accepting, you agree to the{" "}
          <a href="/cookies-policy/" className="policy-link">
            Cookie Policy
          </a>{" "}
          and{" "}
          <a href="/privacy-policy/" className="policy-link">
            Privacy Policy
          </a>{" "}
          of BPPL Holdings PLC.
          <br />
          <button onClick={handleAccept} className="accept-button">
            Accept All
          </button>
        </div>
      </div>
    )
  );
};

export default CookieNotice;
